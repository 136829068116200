export default {
  columns: [
    {
      value: '0',
      text: 'bet_status_id[0]'
    },
    {
      value: '1',
      text: 'bet_status_id[1]'
    },
    {
      value: '2',
      text: 'bet_status_id[2]'
    },
    {
      value: '3',
      text: 'bet_status_id[3]'
    },
    {
      value: '4',
      text: 'bet_status_id[4]'
    },
    {
      value: '5',
      text: 'bet_status_id[5]'
    }
  ],
  table: {
    title: [
      {
        name: 'rp_lottery'
      },
      {
        name: 'ui_bet_time_colon'
      },
      {
        name: 'rp_bet_amount'
      },
      {
        name: 'rp_win_amount'
      },
      {
        name: 'ui_status'
      }
    ],
    keys: ['gameName', 'betTime', 'betAmount', 'bonus', 'statusText']
  },
  detail: {
    keys: [
      'gameName',
      'methodName',
      'betNo',
      'betTime',
      'betAmount',
      'bonus',
      'statusText',
      'status'
    ],
    title: {
      gameName: 'rp_lottery',
      methodName: 'rp_play',
      betNo: 'rp_number',
      betTime: 'ui_bet_time_colon',
      betAmount: 'rp_bet_amount',
      bonus: 'rp_win_amount',
      statusText: 'ui_status'
    }
  }
};
